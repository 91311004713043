import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Checkbox, Dropdown, Button as FlowBiteButton, Label, Select as FlowBiteSelect, TextInput,
} from 'flowbite-react';
import Select, { MultiValue } from 'react-select';
import { DropdownItem } from 'flowbite-react/lib/esm/components/Dropdown/DropdownItem';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { DataProductDataType, useGetMyProductQuery, useUpsertDataProductMutation } from '../../../generated/gql/types';
import {
  OfferWizardPricingPlansRoute, OfferWizardProductVisibilityRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import { DataProductForm } from '../../../productForms';
import Loader from '../../Loader';
import useProductFilters, { ProductLocation } from '../../hooks/useProductFilters';

const formatOptions: { value: string, label_nl: string }[] = [
  { value: 'raw-data', label_nl: 'Ruwe data' },
  { value: '3d', label_nl: '3D-visualisatie' },
  { value: 'improvement', label_nl: 'Gegevensverbeterd product' },
  { value: 'derivative-data', label_nl: 'Afgeleide gegevens' },
  { value: 'algorithm', label_nl: 'Algoritme' },
  { value: 'data-driven', label_nl: 'Gegevensgestuurde service' },
  { value: 'dataset', label_nl: 'Gegevensset' },
  { value: 'decision-support', label_nl: 'Beslissingsondersteuning' },
  { value: 'data-optimized-achievements', label_nl: 'Gegevensgeoptimaliseerde prestaties' },
  { value: 'reports', label_nl: 'Rapporten' },
  { value: 'automated-decisions', label_nl: 'Geautomatiseerde besluitvorming' },
  { value: 'bidirectional', label_nl: 'Bidirectioneel' },
  { value: 'analytic-overview', label_nl: 'Analytisch overzicht' },
];

function YourDataSet() {
  const params = useParams();
  const { data: myProductData, loading: myProductLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });
  const navigate = useNavigate();
  const productFilters = useProductFilters();
  const [odpsJson, setOdpsJson] = useState<any | undefined>(undefined);
  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const {
    register,
    handleSubmit,
  } = useForm<OdpsFields>();

  const [selectedFormats, setSelectedFormats] = useState<string[]>([]);
  const toggleFormat = (format: string) => {
    if (selectedFormats.includes(format)) {
      setSelectedFormats(selectedFormats.filter((f) => f !== format));
    } else {
      setSelectedFormats([...selectedFormats, format]);
    }
  };

  const [selectedNature, setSelectedNature] = useState<string[]>([]);
  const toggleSelectedNature = (nature: string) => {
    if (selectedNature.includes(nature)) {
      setSelectedNature(selectedNature.filter((f) => f !== nature));
    } else {
      setSelectedNature([...selectedNature, nature]);
    }
  };

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  function updateOdpsJson(rawOdps: string | undefined, fields: OdpsFields): string {
    const parsedOdps = JSON.parse(rawOdps ?? '{}');
    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        en: {
          ...parsedOdps?.product?.en ?? [],
          version: fields.version,
          outputFileFormats: selectedFormats,
          documentationUrl: fields.documentationUrl,
          'x-locations': selectedLocations,
          'x-wecity': {
            ...parsedOdps?.product?.en['x-wecity'] ?? [],
            customerApiUrl: fields.apiUrl,
          },
        },
        SLA: {
          ...parsedOdps?.product?.SLA ?? [],
          updateFrequency: {
            ...parsedOdps?.product?.SLA?.updateFrequency ?? [],
            unit: fields.updateFrequency.unit,
            value: parseFloat(fields.updateFrequency.value.toString()),
          },
        },
        dataOps: {
          ...parsedOdps?.product?.dataOps ?? [],
          infrastructure: {
            ...parsedOdps?.product?.dataOps?.infrastructure ?? [],
            region: 'Amsterdam',
            schemaLocationURL: fields.schemaLocationURL,
          },
        },
      },
    });
  }

  useEffect(() => {
    if (myProductData?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, myProductData?.dataMarket.userProduct.id);
      const odps = JSON.parse(myProductData?.dataMarket.userProduct.openDataProductSpecification);
      setOdpsJson(odps);
      setSelectedFormats(odps?.product?.en?.outputFileFormats ?? []);
      setSelectedLocations(
        (odps?.product?.en['x-locations'] ?? [])
          .filter((location: string) => productFilters?.cities.map((city) => city.label).includes(location)),
      );
      setSelectedNature([]); // TODO: Should this be the usecase array or the value proposition?
    }
  }, [myProductData, myProductLoading]);

  const onSubmit = handleSubmit((fields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: myProductData?.dataMarket.userProduct.id ?? params.productId,
          dataType: myProductData?.dataMarket.userProduct.dataType ?? DataProductDataType.Common,
          openDataProductSpecification: updateOdpsJson(myProductData?.dataMarket.userProduct.openDataProductSpecification, fields),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardPricingPlansRoute(params.orgId, params.productId));
    });
  });

  function setLocationlist(e: MultiValue<{ label: string; value: string }>) {
    setSelectedLocations(e.map((l) => l.value));
  }

  const groupedLocations = [
    { label: 'Steden', options: (productFilters?.cities || []).map((l: ProductLocation) => ({ label: l.label, value: l.label })) },
    { label: 'Provincies', options: (productFilters?.provinces || []).map((l: ProductLocation) => ({ label: l.label, value: l.label })) },
    { label: 'Overige locaties', options: (productFilters?.otherLocations || []).map((l: ProductLocation) => ({ label: l.label, value: l.label })) },
  ];

  return (
    <WizardStep form={DataProductForm} currentStep={3} onSubmit={onSubmit}>
      {!myProductLoading && odpsJson
        ? (
          <>
            <FormField fieldName="version" label="Versie van deze dataset" appendAsterix instruction="Alleen nummers en punten">
              <TextInput id="version" placeholder="1.0" {...register('version', { value: odpsJson?.product?.en?.version ?? '', required: true, maxLength: 50 })} />
            </FormField>
            {/* TODO: kan waarschijnlijk netter dan met useState... */}
            <FormField fieldName="nature" label="Wat is de aard van uw data?" appendAsterix>
              {formatOptions.map((option) => (
                <div className="flex gap-2" key={crypto.randomUUID()}>
                  <Checkbox checked={selectedNature.findIndex((v) => option.value === v) >= 0} id={option.value} value={option.value} onChange={() => toggleSelectedNature(option.value)} />
                  <Label htmlFor={option.value} value={option.label_nl} />
                </div>
              ))}
            </FormField>
            <FormField fieldName="formats" label="Formats" appendAsterix>
              <div className="max-w-screen-md border border-1 rounded-xl p-2 flex gap-2">
                <Dropdown id="formats" placeholder="Selecteer" label="Selecteer" inline>
                  <DropdownItem value="JSON" onClick={() => toggleFormat('JSON')}>JSON</DropdownItem>
                  <DropdownItem value="XML" onClick={() => toggleFormat('XML')}>XML</DropdownItem>
                  <DropdownItem value="GraphQL" onClick={() => toggleFormat('GraphQL')}>GraphQL</DropdownItem>
                  <DropdownItem value="plain text" onClick={() => toggleFormat('plain text')}>plain text</DropdownItem>
                </Dropdown>
                {selectedFormats.map((format) => (
                  <Badge key={format} className="px-2">
                    <button
                      type="button"
                      className="flex rounded-l-md text-sm font-medium text-gray-500 center-content"
                      onClick={() => {
                        toggleFormat(format);
                      }}
                    >
                      {format}
                      {selectedFormats.includes(format) && <FontAwesomeIcon className="my-auto ml-2" icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />}
                    </button>
                  </Badge>
                ))}
              </div>
            </FormField>
            <FormField fieldName="locations" label="Locaties" appendAsterix>
              <Select placeholder="Zoek een locatie..." value={selectedLocations.map((l) => ({ label: l, value: l }))} isMulti options={groupedLocations} onChange={(e) => setLocationlist(e)} />
            </FormField>
            <FormField fieldName="update-frequency" label="Update frequentie" hint="De frequentie waarmee de databron geupdate wordt" appendAsterix>
              <div className="flex gap-2">
                <TextInput type="number" id="update-frequency-unit-amount" placeholder="Aantal keer" required {...register('updateFrequency.value', { value: odpsJson?.product?.SLA?.updateFrequency?.value ?? '' })} />
                <div className="my-auto"> per</div>
                <FlowBiteSelect id="update-frequency-unit" required {...register('updateFrequency.unit', { value: odpsJson?.product?.SLA?.updateFrequency?.unit ?? '' })}>
                  <option value="seconds">seconde</option>
                  <option value="minutes">minuut</option>
                  <option value="hours">uur</option>
                  <option value="days">dag</option>
                  <option value="weeks">week</option>
                  <option value="months">maand</option>
                  <option value="years">jaar</option>
                </FlowBiteSelect>
              </div>
            </FormField>
            <FormField label="Schema locatie URL" fieldName="location-url" appendAsterix>
              {odpsJson?.product?.dataOps?.infrastructure?.schemaLocationUrl}
              <TextInput id="location-url" placeholder="https://" required {...register('schemaLocationURL', { value: odpsJson?.product?.dataOps?.infrastructure?.schemaLocationURL ?? '' })} />
            </FormField>
            <FormField label="Documentatie URL" fieldName="documentation-url" appendAsterix>
              <TextInput id="documentation-url" placeholder="https://" required {...register('documentationUrl', { value: odpsJson?.product?.en?.documentationUrl ?? '' })} />
            </FormField>
            <FormField label="API URL" appendAsterix fieldName="api-url">
              <TextInput id="api-url" placeholder="https://" required {...register('apiUrl', { value: odpsJson?.product?.en['x-wecity']?.customerApiUrl ?? '' })} />
            </FormField>

            <div className="flex gap-x-4 mx-auto">
              <Link to={OfferWizardProductVisibilityRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
                  </div>
                </FlowBiteButton>
              </Link>
              {!upsertLoading ? (
                <Button
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Volgende</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type OdpsFields = {
  version: string;
  nature: string[];
  formats: string[];
  locations: string[];
  updateFrequency: {
    value: number;
    unit: string;
  },
  schemaLocationURL: string;
  documentationUrl: string;
  apiUrl: string;
};

export default YourDataSet;
