import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { GenericProductForm } from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import { ProductUpdate_ReviewState_MutationInput, useGetGenericProductQuery, useUpdateGenericProductMutation } from '../../../generated/gql/types';
import CatalogProduct from '../CatalogProduct';
import { CatalogProductRoute, GenericOfferWizardPricePlansroute } from '../../Routes';
import { WeCityOrganizationIdHeader } from '../../../Global';

function GenericYourProductReview() {
  const navigate = useNavigate();
  const params = useParams();

  const { loading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const { data: cmsProduct, loading: cmsProductLoading } = useGetGenericProductQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId ?? '',
    },
  });

  const [updateProduct, { loading: updateLoading }] = useUpdateGenericProductMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const handleSubmit = () => {
    if (cmsProduct?.Product?.id) {
      updateProduct({
        variables: {
          id: cmsProduct?.Product?.id,
          data: {
            reviewState: ProductUpdate_ReviewState_MutationInput.Accepted,
          },
        },
      }).then((_) => {
        navigate(CatalogProductRoute(params.productType, params.productId));
      });
    }
  };

  return (
    <WizardStep form={GenericProductForm} currentStep={4}>
      <CatalogProduct internal preview />
      <div className="flex gap-x-4">
        <Link to={GenericOfferWizardPricePlansroute(params.orgId, params.productId, params.productType)}>
          <Button size="lg" color="light">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
            </div>
          </Button>
        </Link>

        {loading || cmsProductLoading || updateLoading ? (
          <Button
            type="button"
            disabled
            className="opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
          >
            <div className="px-0.5 py-0.5">Product publiceren</div>
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => handleSubmit()}
            className="items-center justify-self-center self-start rounded-lg text-center font-medium"
          >
            <div className="px-0.5 py-0.5">Product publiceren</div>
          </Button>
        )}
      </div>
    </WizardStep>
  );
}

export default GenericYourProductReview;
