import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import {
  CatalogRoute,
  MyOfferingsOverviewRoute,
} from '../../Routes';
import InfoButton from '../../library/InfoButton';

function Congratulations() {
  const params = useParams();

  return (
    <div
      className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full flex-col p-10 bg-white space-y-2 my-5"
    >
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <div className="text-gray-900 text-3xl font-bold self-start">Gefeliciteerd! Je hebt een product ingediend</div>

      <div className="text-gray-900 text-base font-bold self-start">
        Het kan tot 3 dagen duren voordat uw data-product is gereviewed is.
      </div>

      <div className="flex gap-x-4">
        <Link to={CatalogRoute()}>
          <FlowBiteButton size="lg" color="light">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              <div className="text-xs lg:text-sm font-medium w-full">Terug naar catalogus</div>
            </div>
          </FlowBiteButton>
        </Link>

        <Link to={MyOfferingsOverviewRoute(params.orgId)}>
          <InfoButton>
            <div className="text-xs lg:text-sm font-medium w-full">Terug naar mijn aanbod</div>
          </InfoButton>
        </Link>
      </div>
    </div>
  );
}

export default Congratulations;
