import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timeFrameToString, PricePlanTypeToString } from '../../Helpers';
import {
  PricePlan,
  PricePlan_PricePlanType,
  PricePlan_RecurringPricePer,
  useDeletePricePlanMutation,
  useGetPricePlansForOrgQuery,
} from '../../generated/gql/types';
import InfoButton from '../library/InfoButton';
import Badge from '../library/Badge';
import Card from '../library/Card';
import {
  PricePlansOverviewRoute,
  PricePlanWizardRoute,
} from '../Routes';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';
import { WeCityOrganizationIdHeader } from '../../Global';

function PricePlansOverview() {
  const params = useParams();
  const { data, loading, refetch } = useGetPricePlansForOrgQuery({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      orgId: params.orgId ?? '',
    },
  });

  const [deletePricePlan] = useDeletePricePlanMutation({
    context: {
      clientName: 'cms',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const defaultOrgId = useDefaultDataMarketOrganization();
  const [myPricePlans, setMyPricePlans] = useState<PricePlan[] | undefined>(undefined);

  const deletePlan = (id: string) => {
    deletePricePlan({
      variables: {
        id,
      },
    }).then((_) => {
      refetch();
    });
  };

  useEffect(() => {
    if (data?.PricePlans?.docs && !loading) {
      setMyPricePlans(data?.PricePlans?.docs as PricePlan[]);
    }
  }, [data, loading]);

  const navigate = useNavigate();
  useEffect(() => {
    if (params.orgId === ':orgId') {
      navigate(PricePlansOverviewRoute(defaultOrgId));
    }
  }, [defaultOrgId]);

  function renderPricePlan(pricePlan: PricePlan) {
    if (pricePlan) {
      return (
        <Card
          key={`pricePlanCard-${pricePlan.id}`}
          header={(
            <div
              className="hidden pt-0 lg:inline-flex w-full h-36 bg-blue-400 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
            >
              <FontAwesomeIcon
                icon={icon({ name: 'coins', family: 'classic', style: 'solid' })}
                size="2x"
                className="text-primary-800"
              />
            </div>
          )}
          backgroundColor="white"
          isMini={false}
        >
          <div className="flex w-full">
            <div className="flex-col grow p-3 lg:p-5 justify-between w-full items-center inline-flex gap-3">
              <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
                <Badge
                  key="inprogress"
                  color="purple"
                >
                  {PricePlanTypeToString(pricePlan.pricePlanType as PricePlan_PricePlanType)}
                </Badge>
              </div>

              <div className="flex-col justify-start items-start inline-flex w-full">
                <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
                  {pricePlan.name}
                </div>
                <div
                  className="basis-0 text-gray-500 text-base font-normal leading-normal"
                >
                  <p className="line-clamp-3 mb-2 text-sm lg:text-base">
                    {pricePlan.description}
                  </p>
                </div>
              </div>
              <div className="w-full bg-gray-200 p-3 py-5 rounded-xl flex flex-col">
                {(pricePlan.pricePlanType === PricePlan_PricePlanType.OneTime || pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                  <div>
                    <span className="text-gray-900 text-xl font-bold">
                      {`€${pricePlan.priceOneTime} `}
                    </span>
                    eenmalig
                  </div>
                )}
                {pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription && (
                  <div className="h-[2px] w-full bg-gray-400 my-3" />)}
                {(pricePlan.pricePlanType === PricePlan_PricePlanType.Subscription || pricePlan.pricePlanType === PricePlan_PricePlanType.OneTimeSubscription) && (
                  <div>
                    <span className="text-gray-900 text-xl font-bold">
                      {`€${pricePlan.priceRecurring} `}
                    </span>
                    {'per '}
                    {timeFrameToString(pricePlan.recurringPricePer as PricePlan_RecurringPricePer)}
                  </div>
                )}
                <div className="text-sm border-l-[2px] pl-2 ml-1 border-gray-400">
                  {
                    pricePlan.minimumSubscriptionTime
                    && (
                      <div className="flex justify-between">
                        <div>Minimale afnameperiode</div>
                        <div>{`1 ${timeFrameToString(pricePlan.minimumSubscriptionTime)}`}</div>
                      </div>
                    )
                  }
                  {
                    pricePlan.paymentPeriod
                    && (
                      <div className="flex justify-between">
                        <div>Factureringstermijn</div>
                        <div>
                          {`1 ${timeFrameToString(pricePlan.paymentPeriod)}`}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="flex pt-2 justify-between items-center w-full gap-x-4">
                <button
                  onClick={() => deletePlan(pricePlan.id)}
                  type="button"
                  className="text-red-700"
                >
                  Verwijderen
                </button>
                <Link to={PricePlanWizardRoute(params.orgId, pricePlan.id)}>
                  <InfoButton>
                    <div className="text-xs lg:text-sm font-medium">Bewerken</div>
                    <FontAwesomeIcon
                      icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                      size="lg"
                      className="text-white"
                    />
                  </InfoButton>
                </Link>
              </div>
            </div>
          </div>
        </Card>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">
      {(!loading) ? (
        <div
          className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column"
        >

          <div key="new-offer" className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
            <div className="p-4 flex-col space-y-4">
              <FontAwesomeIcon
                size="2x"
                icon={icon({ name: 'thumbs-up', family: 'classic', style: 'regular' })}
              />
              <div className="text-gray-900 text-2xl">
                Stel een nieuw prijsplan op
              </div>
              <div className="text-gray-500 text-base pb-10">
                Je organisatie is goedegekeurd door WeCity.
              </div>
              <Link target="_blank" to={PricePlanWizardRoute(defaultOrgId, crypto.randomUUID())}>
                <InfoButton fullSized>
                  <div className="text-xs lg:text-sm font-medium w-full">Nieuw prijsplan maken</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          </div>
          {myPricePlans?.map((plan) => (
            renderPricePlan(plan as PricePlan)
          ))}
        </div>
      ) : (
        <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
        </div>
      )}
    </div>
  );
}

export default PricePlansOverview;
