import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Badge, Dropdown } from 'flowbite-react';
// import Select, { MultiValue } from 'react-select';
import {
  CatalogProductType, ProductVisibility,
} from '../../Global';
import CategoriesToIcons from './CategoriesToIcons';
import useProductFilters, { ProductLocation, ProductTheme } from '../hooks/useProductFilters';
import { capitalize } from '../../Helpers';

export type ProductFilter = {
  themes: ProductTheme[];
  types: CatalogProductType[];
  locations: ProductLocation[];
};

interface IFilterProps {
  // setLocationFilter: (locations: ProductLocation[]) => void,
  addFilter: (filter: string, value: ProductTheme | ProductVisibility | CatalogProductType | ProductLocation, add: boolean) => void,
  removeFilter: (name: string) => void,
  clearFilters: () => void,
  filters: ProductFilter
}

const renderDropdownButton = (name: string, dropdownIcon: any) => (
  <div className="px-2">
    <span className="mx-2 text-gray font-normal">
      {name}
      <FontAwesomeIcon className="ml-2" icon={dropdownIcon} />
    </span>
  </div>
);

function Filters({
  addFilter, removeFilter, clearFilters, filters,
}: IFilterProps) {
  const productFilters = useProductFilters();
  const appliedFilters = [
    ...filters.themes.map((theme) => ({ name: theme.label, list: 'theme' })),
    ...filters.locations.map((location) => ({ name: location.label, list: 'location' })),
  ];

  // Commented out for possible later use, after feedback
  // function setLocationlist(e: MultiValue<{ label: string; value: ProductLocation }>) {
  //   setLocationFilter(e.map((l) => l.value));
  // }

  // const groupedLocations = [
  //   { label: 'Steden', options: (productFilters?.cities || []).map((l: ProductLocation) => ({ label: l.label, value: l })) },
  //   { label: 'Provincies', options: (productFilters?.provinces || []).map((l: ProductLocation) => ({ label: l.label, value: l })) },
  //   { label: 'Overige locaties', options: (productFilters?.otherLocations || []).map((l: ProductLocation) => ({ label: l.label, value: l })) },
  // ];

  return (
    <div className="flex flex-col gap-0.5 mb-1 w-full">
      <ul className="mb-5 pb-5 gap-3 overflow-scroll w-screen-xl flex">
        {
          productFilters?.themes.filter((theme) => theme.icon).map((theme) => (
            theme.icon && (
              <li key={`${theme.label}_${theme.type}`}>
                <button onClick={() => addFilter('theme', theme, true)} type="button" key={theme.label} className="h-32 w-36 bg-gray-50 rounded-xl shadow-lg border-2 border-gray-300 hover:bg-white cursor-pointer flex flex-col">
                  <div className="justify-center m-auto">
                    <CategoriesToIcons categories={[theme.icon]} />
                  </div>
                  <div className="mx-auto mb-3">{capitalize(theme.label)}</div>
                </button>
              </li>
            )
          ))
        }
      </ul>
      <div className="flex relative gap-0.5">
        <Dropdown label="" renderTrigger={() => renderDropdownButton('Thema', icon({ name: 'chevron-down', family: 'classic', style: 'solid' }))}>
          <div className="max-h-[500px] overflow-y-scroll">
            {
              productFilters?.themes.map((theme) => (
                <Dropdown.Item key={`${theme.label}_${theme.type}`} onClick={() => addFilter('theme', theme, true)}>
                  {capitalize(theme.label)}
                </Dropdown.Item>
              ))
            }
          </div>
        </Dropdown>
        <Dropdown label="" renderTrigger={() => renderDropdownButton('Locatie', icon({ name: 'chevron-down', family: 'classic', style: 'solid' }))}>
          <div className="max-h-[500px] overflow-y-scroll">
            {
              productFilters?.allLocations.map((location) => (
                <Dropdown.Item key={`${location.label}_${location.type}`} onClick={() => addFilter('location', location, true)}>
                  {location.label}
                </Dropdown.Item>
              ))
            }
          </div>
        </Dropdown>
        {/*
        Maybe we can use this later
        <Select placeholder="Zoek een locatie..." value={filters.locations.map((l) => ({ label: l.label, value: l }))} isMulti options={groupedLocations} onChange={(e) => setLocationlist(e)} /> */}
      </div>
      <div className="bg-gray-400 mt-3 mb-2 h-[2px] w-full" />
      <ul className="mb-5 flex max-w-screen-xl flex-wrap">
        {appliedFilters.map((filter) => (
          <li key={filter.name}>
            <Button size="small" color="gray" className="bg-transparent border-0" onClick={() => removeFilter(filter.name)}>
              <Badge className="flex flex-row px-2" color="info">
                <span className="mx-2 text-gray font-normal">
                  {capitalize(filter.name)}
                  <FontAwesomeIcon className="ml-2" icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} />
                </span>
              </Badge>
            </Button>
          </li>
        ))}
        {
          appliedFilters.length > 0 && (
            <Button size="small" color="gray" className="bg-transparent ml-5 border-0" onClick={() => clearFilters()}>
              <span className="mx-2 text-gray font-normal underline">
                Clear All
              </span>
            </Button>
          )
        }
      </ul>
    </div>
  );
}

export default Filters;
