import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Badge,
  Button as FlowBiteButton, Label, TextInput, Textarea, Button,
  Checkbox,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import {
  DataProductDataType,
  useGetMyProductQuery,
  useUpsertDataProductMutation,
} from '../../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import {
  OfferWizardAutoFillOdpsRoute,
  OfferWizardProductVisibilityRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import { DataProductForm } from '../../../productForms';
import Loader from '../../Loader';

function YourProduct() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, loading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });
  const keyWordRef = useRef<HTMLInputElement>(null);
  const [keyWords, setKeyWords] = useState<string[]>([]);
  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const [odpsJson, setOdpsJson] = useState<any | undefined>(undefined);
  const [dmiCommon, setDmiCommon] = useState<DataProductDataType>(DataProductDataType.Common);

  const {
    register,
    handleSubmit,
  } = useForm<OdpsFields>();

  useEffect(() => {
    if (data?.dataMarket?.userProduct?.openDataProductSpecification && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
      const odps = JSON.parse(data?.dataMarket.userProduct.openDataProductSpecification);
      setOdpsJson(odps);
      setKeyWords(odps?.product?.en.categories ?? []);
      setDmiCommon(data?.dataMarket?.userProduct?.dataType ?? DataProductDataType.Common);
    }
    if (!loading && !data?.dataMarket?.userProduct?.openDataProductSpecification) setOdpsJson({ id: params.productId });
  }, [data, loading]);

  const toggleDmiCommon = (e: HTMLInputElement) => {
    if (e.checked) setDmiCommon(DataProductDataType.Common);
    else setDmiCommon(DataProductDataType.Personal);
  };

  function updateOdpsJson(rawOdps: string | undefined, fields: OdpsFields): string {
    const parsedOdps = JSON.parse(rawOdps ?? '{}');

    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        en: {
          ...parsedOdps?.product?.en ?? [],
          status: 'production',
          type: 'dataset',
          name: fields.name,
          description: fields.description,
          categories: [],
        },
      },
    });
  }

  const onCategorySubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const newKeyword = keyWordRef.current!.value;
      keyWordRef.current!.value = ''; // reset before rerender
      setKeyWords([...(keyWords.filter((k) => k !== newKeyword)), newKeyword]);
    }
  };

  const onCategoryBlur = () => {
    const newKeyword = keyWordRef.current!.value;
    if (newKeyword === '') return;
    keyWordRef.current!.value = ''; // reset before rerender
    setKeyWords([...(keyWords.filter((k) => k !== newKeyword)), newKeyword]);
  };

  const onSubmit = handleSubmit((fields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: dmiCommon,
          openDataProductSpecification: updateOdpsJson(data?.dataMarket.userProduct.openDataProductSpecification, fields),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardProductVisibilityRoute(params.orgId, params.productId));
    });
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <WizardStep form={DataProductForm} currentStep={1} onSubmit={onSubmit} key={loading ? 'loading' : 'loaded'}>
      {!loading && odpsJson ? (
        <>
          <FormField fieldName="name" label="Kies een naam/titel van je data" instruction="Max. 50 karakters" appendAsterix>
            <TextInput
              {...register('name', { value: odpsJson?.product?.en?.name, required: true, maxLength: 50 })}
            />
          </FormField>
          <FormField fieldName="license" label="Licentie">
            <div className="flex gap-2">
              <Checkbox
                defaultChecked={dmiCommon === DataProductDataType.Common}
                value="commons"
                onChange={(e: ChangeEvent<HTMLInputElement>) => toggleDmiCommon(e.currentTarget)}
              />
              <Label value="DMI Common" />

            </div>
          </FormField>

          <FormField fieldName="description" label="Beschrijf wat je met deze data kan doen" instruction="Max. 1000 karakters" appendAsterix>
            <Textarea
              rows={8}
              id="description"
              placeholder="bijv. Gemeentelijke bereikbaarheid van publieksvoorzieningen..."
              required
              maxLength={1000}
              {...register('description', { value: odpsJson?.product?.en?.description ?? '' })}
              defaultValue={odpsJson?.product?.en?.description ?? ''}
            />
          </FormField>

          {/* todo: REMOVE this + DMI COMMON */}
          <FormField fieldName="keywords" label="Sleutelwoorden" appendAsterix instruction="Max. 75 karakters per stuk">
            <div className="border-1 border rounded-xl p-3">
              <div className="relative flex flex-wrap gap-2">
                <div className="left-0 flex items-center max-w-md">
                  <input
                    ref={keyWordRef}
                    id="keywordInput"
                    type="text"
                    className="left-0 flex-grow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    placeholder="bijv. Parkeeraanbod"
                    name="input"
                    maxLength={75}
                    onKeyDown={onCategorySubmit}
                    onBlur={onCategoryBlur}
                  />
                  <button
                    type="button"
                    className="-left-6 relative rounded-l-md text-sm font-medium text-gray-500"
                    onClick={() => {
                      keyWordRef.current!.value = '';
                    }}
                  >
                    <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />
                  </button>
                </div>
                {
                  keyWords.map((keyword: string) => (
                    <Badge className="px-2 inline" color="info" key={keyword}>
                      <button
                        type="button"
                        className="flex rounded-md text-sm font-medium text-gray-500 center-content"
                        onClick={() => {
                          setKeyWords(keyWords.filter((v) => v !== keyword));
                        }}
                      >
                        {keyword}
                        <FontAwesomeIcon
                          className="my-auto ml-2"
                          icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })}
                          color="gray"
                        />
                      </button>
                    </Badge>
                  ))
                }
              </div>
            </div>
          </FormField>
          <div className="flex gap-x-4">
            <Link to={OfferWizardAutoFillOdpsRoute(params.orgId, params.productId)}>
              <FlowBiteButton size="lg" color="light">
                <div className="w-full justify-center items-center gap-2 inline-flex">
                  <div className="text-xs lg:text-sm font-medium w-full">Terug</div>
                </div>
              </FlowBiteButton>
            </Link>

            {!upsertLoading ? (
              <Button
                type="submit"
                className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Volgende</div>
              </Button>
            ) : (
              <Button
                disabled
                type="button"
                className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Volgende</div>
              </Button>
            )}
          </div>
        </>
      ) : (<Loader />)}
    </WizardStep>
  );
}

type OdpsFields = {
  name: string
  description: string,
  categories: string[],
  dmiCommon: DataProductDataType
};

export default YourProduct;
