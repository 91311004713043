import odps from './odps.json';

const GenericProductForm: IWizardForm = {
  fieldCount: 10,
  lang: 'nl',
  title: 'Sales Wizard',
  steps: [
    {
      name: 'Begin',
      description: 'Help potentiële klanten om je product te leren kennen.',
    },
    {
      name: 'Thema\'s',
      description: ' Thema\'s helpen klanten om je product te vinden in de WeCity shop.',
    },
    {
      name: 'Zichtbaarheid',
      description: ' Wie kunnen je producten zien en gebruiken?',
    },
    {
      name: 'Prijsplannen',
      description: ' Maak prijsplannen voor je product.',
    },
    {
      name: 'Controleren',
      description: ' Dit is een voorbeeld van je productpagina. Klanten zullen kaarten zien als ze browsen in de shop.',
    },
  ],
};

const DataProductForm: IWizardForm = {
  fieldCount: Object.keys(odps).length,
  lang: 'nl',
  title: 'Sales Wizard',
  steps: [
    {
      name: 'Vooraf vullen',
      description: 'Dit is optioneel. Je kan een ingevulde ODPS JSON bestand hier kopieren om de velden automatisch in te vullen.',
    },
    {
      name: 'Je product',
      description: 'Vertel ons over je product.',
    },
    {
      name: 'Zichtbaarheid',
      description: 'Zorg dat je product opvalt op ons platform.',
    },
    {
      name: 'Jouw dataset',
      description: 'Vul hier gegevens in over je data.',
    },
    {
      name: 'Bundels',
      description: 'Je kan tot drie bundels maken voor je product: Brons, Zilver en Goud.',
    },
    {
      name: 'Licenties en voorwaarden',
      description: 'Je kan het gebruik van je product beperken voor correct gebruik.',
    },
    {
      name: 'Data aanbieder',
      description: 'Vul hier de gegevens in van de data-aanbieder.',
    },
    {
      name: 'Controleren',
      description: 'Controleer hoe je product eruit ziet op ons platform.',
    },
  ],
};

export interface IWizardForm {
  fieldCount: number,
  lang: string,
  title: string,
  steps: IWizardStep[],
}

interface IWizardStep {
  name: string,
  description: string
}

export { DataProductForm, GenericProductForm };
