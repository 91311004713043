import React from 'react';
import { Link } from 'react-router-dom';
import { CatalogRoute } from '../Routes';

function MySubscriptionsHero() {
  return (
    <div className="pt-2 lg:py-10 text-center flex-col items-center space-y-6 bg-gray-50">
      <div className="mx-auto max-w-screen-xl flex-col">
        <h1 className="text-3xl lg:text-5xl font-extrabold text-gray-900 leading-none lg:leading-10 pb-2">
          Mijn Aankopen
        </h1>

        <div className="leading-tight text-sm lg:text-xl text-gray-500 sm:px-16 xl:px-32">
          Bekijk uw abonnementen op producten uit onze
          {' '}
          <Link to={CatalogRoute()} className="text-primary-500 font-bold">
            Catalogus
          </Link>
          . Per product vindt u ook alle documentatie en digitale toegang tot uw producten.
        </div>
      </div>
    </div>
  );
}

export default MySubscriptionsHero;
