import React from 'react';
import { Link } from 'react-router-dom';
import { MySubscriptionsOverviewRoute } from '../Routes';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

function MySubscriptionsLink() {
  const defaultOrgId = useDefaultDataMarketOrganization();

  if (!defaultOrgId) {
    return (<span className="text-primary-500 font-bold">Mijn Aankopen</span>);
  }

  return (
    <Link
      to={MySubscriptionsOverviewRoute(defaultOrgId)}
      className="text-primary-500 font-bold"
    >
      Mijn Aankopen (
      {defaultOrgId}
      )
    </Link>
  );
}

export default MySubscriptionsLink;
