import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import DataProductCard from '../library/DataProductCard';
import UseCaseCard from '../library/UseCaseCard';
import Hero from './Hero';
import {
  DataProduct, Product,
  useGetDataProductsQuery, useGetGenericProductsQuery, useSearchDataProductsLazyQuery,
} from '../../generated/gql/types';
import { CatalogProductRoute } from '../Routes';
import validateOdps from '../../Helpers';
import Searchbar from '../library/Searchbar';
import Filters, { ProductFilter } from '../library/Filters';
import ImageCardFilters from '../library/ImageCardFilters';
import { CatalogProductType, ProductVisibility } from '../../Global';
import { ProductLocation, ProductTheme } from '../hooks/useProductFilters';
import ProductCard from '../library/ProductCard';

function CatalogProductsOverview() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { idTokenPayload } = useOidcIdToken();

  const [filters, setFilters] = useState<ProductFilter>({
    themes: [],
    types: [],
    locations: [],
  });

  const addFilter = (filter: string, value: ProductTheme | ProductVisibility | CatalogProductType | ProductLocation) => {
    const combinedFilters = [...filters.themes, ...filters.types, ...filters.locations];
    if (combinedFilters.some((f) => f.label === value.label)) { return; }
    switch (filter) {
      case 'theme':
        setFilters({ ...filters, themes: [...filters.themes, value as ProductTheme] });
        break;
      case 'visibility':
        setFilters({ ...filters, types: [...filters.types, value as ProductVisibility] });
        break;
      case 'location':
        setFilters({ ...filters, locations: [...filters.locations, value as ProductLocation] });
        break;
      default:
        break;
    }
  };
  const scrollToRef = React.useRef<HTMLDivElement>(null);
  const setFilter = (filter: string, value: ProductTheme | ProductVisibility | ProductLocation) => {
    scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
    switch (filter) {
      case 'theme':
        setFilters({ types: [], locations: [], themes: [value as ProductTheme] });
        break;
      case 'visibility':
        setFilters({ locations: [], themes: [], types: [value as ProductVisibility] });
        break;
      case 'location':
        setFilters({ themes: [], types: [], locations: [value as ProductLocation] });
        break;
      default:
        break;
    }
  };

  // const setLocationFilter = (locations: ProductLocation[]) => {
  //   setFilters({ ...filters, locations });
  // };

  const removeFilter = (name: string) => {
    const newFilters = {
      themes: filters.themes.filter((theme) => theme.label !== name),
      types: filters.types.filter((type) => type.label !== name),
      locations: filters.locations.filter((location) => location.label !== name),
    };
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({
      themes: [],
      types: [],
      locations: [],
    });
  };

  const { data: dataProducts, loading: dataProductsLoading } = useGetDataProductsQuery({
    fetchPolicy: 'network-only',
  });
  const { data: cmsProducts, loading: cmsProductsLoading } = useGetGenericProductsQuery({
    context: { clientName: 'cms' },
    fetchPolicy: 'network-only',
  });
  const [searchDataProducts, {
    data: searchData, loading: searchLoading,
  }] = useSearchDataProductsLazyQuery({
    fetchPolicy: 'network-only',
  });

  let timer: NodeJS.Timeout | null = null;

  const submitSearch = (searchString: string) => {
    if (searchString.length < 3) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchParams({ searchString });
    }, 500);
  };

  useEffect(() => {
    const query = searchParams.get('searchString');
    if (query) {
      searchDataProducts({
        fetchPolicy: 'network-only',
        variables: {
          query: {
            searchString: query,
          },
        },
      });
    }
  }, [searchParams]);

  const compareCategoriesToFilters = (categories: string[]) => {
    // const odps = JSON.parse(product.openDataProductSpecification);
    if (filters.themes.length > 0) {
      if (!filters.themes.some((theme) => categories.toString().toLowerCase().includes(theme.label.toLowerCase()))) {
        return false;
      }
    }
    if (filters.locations.length > 0) {
      if (!filters.locations.some((location) => categories.toString().toLowerCase().includes(location.label.toLowerCase()))) {
        return false;
      }
    }
    return true;
  };

  const filterDataProductList = (dps: Partial<DataProduct>[]) => dps.filter((p) => {
    const odps = JSON.parse(p.openDataProductSpecification);
    return compareCategoriesToFilters(odps.product?.en?.categories);
  });
  const filterProductList = (ps: Product[]) => ps?.filter((product) => compareCategoriesToFilters(product?.tags?.map((t) => t.label!) ?? [])); // TODO: change to categories

  function renderDataProductWithUseCases(dp: Partial<DataProduct>) {
    const odps = validateOdps(dp.openDataProductSpecification);

    if (odps) {
      if (odps.product.en.useCases && odps.product.en.useCases.length > 0 && dp.id) {
        return (
          <Fragment key={`catalogproduct-${dp.id}`}>
            <DataProductCard odps={odps} productRoute={CatalogProductRoute('dataproduct', dp.id)} />
            <UseCaseCard productId={dp.id} useCase={odps.product.en.useCases[0].useCase} />
          </Fragment>
        );
      }
      return (
        <DataProductCard key={`catalogproduct-${dp.id}`} odps={odps} productRoute={CatalogProductRoute('dataproduct', dp.id)} />
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment key={`catalogproduct-${dp.id}`} />;
  }

  function renderGenericProduct(product: Product) {
    const hasDmiCommonPricePlan = product.pricePlans?.some((p) => p.value?.dmiCommon);
    return (
      <Fragment key={`catalogproduct-${product.id}`}>
        <ProductCard
          title={product.title}
          description={product.description}
          themes={product.themes?.map((t) => t.label!) || []}
          tags={product.tags?.map((t) => t.label!)}
          providerName={product.productDataHolder}
          productType={product.productType}
          productRoute={idTokenPayload ? CatalogProductRoute(product.productType, product.id || '') : undefined}
          hasDmiCommonPricePlan={hasDmiCommonPricePlan}
        />
      </Fragment>
    );
  }

  return (
    <>
      <Hero />
      <ImageCardFilters setFilter={setFilter} />
      <div className="mx-auto relative max-w-screen-2xl flex justify-center mb-3 w-full bg-gray-100">
        <Searchbar submitSearch={submitSearch} />
      </div>
      <div className="mx-auto relative max-w-screen-2xl flex justify-between w-full bg-gray-100" ref={scrollToRef}>
        <Filters addFilter={addFilter} removeFilter={removeFilter} clearFilters={clearFilters} filters={filters} />
      </div>
      <div className="mx-auto relative max-w-screen-2xl flex items-center justify-center w-full bg-gray-100">

        {(!dataProductsLoading && !cmsProductsLoading && !searchLoading) ? (
          <div
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 pb-12 lg:w-full break-inside-avoid-column"
          >
            {searchParams.get('searchString') && searchData?.dataProductSearch ? (
              <>
                {(filterDataProductList(searchData.dataProductSearch) ?? []).map(
                  (dataProduct: Partial<DataProduct>) => renderDataProductWithUseCases(dataProduct),
                )}
              </>
            ) : (
              <>
                {filterDataProductList(dataProducts?.dataMarket.products ?? []).map(
                  (dataProduct: Partial<DataProduct>) => renderDataProductWithUseCases(dataProduct),
                )}
                {filterProductList((cmsProducts?.Products?.docs ?? []) as Product[]).map(
                  (product: Product) => renderGenericProduct(product),
                )}
              </>
            )}
          </div>
        ) : (
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
            <div
              key="loading-1"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              key="loading-2"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              key="loading-3"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CatalogProductsOverview;
