import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DataProduct, Product, useDeleteDataProductMutation,
  useDeleteGenericProductMutation, useGetGenericProductsForOrgQuery,
  useGetMyProductsQuery,
} from '../../generated/gql/types';
import {
  CatalogInternalProductRoute,
  MyOfferingsOverviewRoute,
  SelectOfferWizardRoute,
} from '../Routes';
import { OfferingsLocalStorageKey, WeCityOrganizationIdHeader } from '../../Global';
import {
  Offer, UnifiedProduct, unifyDataProduct, unifyGenericProduct,
} from '../../Helpers';
import InfoButton from '../library/InfoButton';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';
import UnifiedProductOfferCard from '../library/UnifiedOfferCard';
import LinkPriceplan from '../library/LinkPriceplan';

function MyOfferingsOverview() {
  const params = useParams();
  const { data: dataProducts, loading, refetch } = useGetMyProductsQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const { data: genericProducts, refetch: refetchGeneric } = useGetGenericProductsForOrgQuery({
    context: {
      clientName: 'cms',
      fetchPolicy: 'network-only',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      orgId: params.orgId ?? '',
    },
  });
  const [deleteProduct] = useDeleteGenericProductMutation({
    context: {
      clientName: 'cms',
      fetchPolicy: 'network-only',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const refetchAll = () => {
    refetch();
    refetchGeneric();
  };
  const [deleteDataProduct] = useDeleteDataProductMutation({});

  const navigate = useNavigate();
  const defaultOrgId = useDefaultDataMarketOrganization();
  useEffect(() => {
    if (params.orgId === ':orgId') {
      navigate(MyOfferingsOverviewRoute(defaultOrgId));
    }
  }, [defaultOrgId]);

  const unifiedGenericProducts = genericProducts?.Products?.docs?.map((product) => unifyGenericProduct(product as Product)) ?? [];
  const unifiedDataProducts = dataProducts?.dataMarket.userProducts?.map((product) => unifyDataProduct(product as DataProduct)) ?? [];

  const unifiedProductList: UnifiedProduct[] = [...unifiedGenericProducts, ...unifiedDataProducts];

  function deleteOfferFromLocalStorage(id: string) {
    const offers = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];
    const updatedOffers = offers.filter((o) => o.id !== id);
    localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(updatedOffers));
  }

  function deleteOffer(type: string, id: string) {
    if (type === 'dataproduct') {
      deleteDataProduct({
        variables: {
          id,
        },
        context: {
          headers: {
            [WeCityOrganizationIdHeader]: params.orgId,
          },
        },
      }).then((_) => {
        deleteOfferFromLocalStorage(id);
        refetch();
      });
    } else {
      deleteProduct({
        variables: {
          id,
        },
      }).then((_) => {
        deleteOfferFromLocalStorage(id);
        refetchGeneric();
      });
    }
  }

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">
      {(!loading) ? (
        <div
          className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 lg:w-full break-inside-avoid-column"
        >

          <div key="new-offer" className="flex bg-white rounded-lg max-w-sm break-inside-avoid">
            <div className="p-4 flex-col space-y-4">
              <FontAwesomeIcon
                size="2x"
                icon={icon({ name: 'thumbs-up', family: 'classic', style: 'regular' })}
              />
              <div className="text-gray-900 text-2xl pb-3">
                Bied een nieuw product aan
              </div>
              <Link to={SelectOfferWizardRoute(defaultOrgId)}>
                <InfoButton fullSized>
                  <div className="text-xs lg:text-sm font-medium w-full">Start met het aanbieden van een nieuw product!</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          </div>
          {/* change to unifiedProducts when dataproducts can be linked as well */}
          <LinkPriceplan products={unifiedGenericProducts} refetch={refetchAll} />
          {
            unifiedProductList.map((product) => (
              <UnifiedProductOfferCard
                productRoute={CatalogInternalProductRoute(product.productType.value, product.id)}
                key={product.id}
                product={product}
                deleteProduct={(type, id) => deleteOffer(type, id)}
              />
            ))
          }
        </div>
      ) : (
        <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
        </div>
      )}
    </div>
  );
}

export default MyOfferingsOverview;
